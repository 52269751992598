<template>
  <div style="width: 100%; height: 100%">
    <div class="video-container">
      <video
        :src="videoLink"
        id="videoPlay"
        controls="controls"
        class="video"
        oncontextmenu="return false;"
        controlslist="nodownload"
      >
        您的浏览器不支持 video 视屏播放。
      </video>
    </div>
  </div>
</template>

<script>
import { getTutorialVideoList } from "../../api/eae";

export default {
  name: "index",
  data() {
    return {
      //https://mainbucket.learningfirst.cn/EAE%20Instructional%20Video-20230615.mp4
      videoLink: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getTutorialVideoList().then((res) => {
        let result = res.data.data;
        if (result.length > 0) {
          this.videoLink = result[0].link;
        }
      });
    },
  },
};
</script>

<style scoped>
.video-container {
  max-width: 1028px;
  margin: 32px auto;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 3px 15px 1px rgba(93, 91, 181, 0.16);
}

#videoPlay {
  width: 100%;
}
</style>
